/**
 * SAMPLE RESPONSES IS NEEDED HARDCODED DATA
 * DO NO DELETE UNLESS YOU'RE MODIFYING THE LOGIC/DATA
 */
export const sampleResponses = [
  {
    id: Math.floor(Math.random() * Date.now()),
    label: "Yes",
    score: 10,
    hasCamera: false,
    hasComment: 1,
    color: "#31e20c",
    isChecked: false,
  },
  {
    id: Math.floor(Math.random() * Date.now()),
    label: "No",
    score: 0,
    hasCamera: false,
    hasComment: 1,
    color: "#f30b0b",
    isChecked: false,
  },
  {
    id: Math.floor(Math.random() * Date.now()),
    label: "N/A",
    score: 0,
    hasCamera: false,
    hasComment: 1,
    color: "black",
    isChecked: false,
  },
];
