import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
    getTicketDetails,
    getTicketThread,
    sendTicketMessage,
} from "../../../api/services/TicketsServices";
import { ERROR } from "../../../components/general/app-toast/AppToast";
import UseAppToast from "../../../helpers/hooks/use-app-toast";
import { UPDATE_PROP } from "../../../store/ActionTypes";
import { getUserFromLS } from "../../../helpers/Storage";
import { useTranslation } from "react-i18next";
import { extractErrorMessage } from "../../../helpers/Common";

export const INITIAL_STATE = {
    response: "",
    statuses: [
        {
            id: 1,
            name: "Open",
        },
        {
            id: 2,
            name: "In Progress",
        },
        {
            id: 3,
            name: "Awaiting Feedback",
        },
        {
            id: 4,
            name: "Solved",
        },
        {
            id: 5,
            name: "Closed",
        },
    ],
    followup: [],
    ticketsLoading: false,
    messagesLoading: false,
    ticketDetails: {},
};

const Logic = (dispatch, state) => {
    const { ticketId } = useParams();
    const { addToast } = UseAppToast();
    const { t, i18n } = useTranslation();
    // const user = useSelector((state) => state.user);
    const user = getUserFromLS();

    const handleSendMessage = (data) => {
        // {
        //   id: ticketId,
        //   type: "agent",
        //   message: message.message,
        //   attachments:[],
        //   reference_id: state.followup.length - 1,
        //   name:state.ticketDetails.assignee
        // }

        const id = state.followup[0]?.id === undefined ? "" : state.followup[0]?.id;

        const formData = new FormData();
        formData.append("ticket_id", ticketId);
        formData.append("type", "customer");
        formData.append("reference_id", id);
        formData.append("name", user);
        formData.append("message", data.message);
        data.attachments.forEach((attachment, index) => {
            formData.append(`attachments[]`, attachment);
        });
        console.log("FormData Contents:");
        for (const pair of formData.entries()) {
            console.log(`${pair[0]}: ${pair[1]}`);
        }
        dispatch({
            payload: [
                {
                    type: UPDATE_PROP,
                    prop: `messagesLoading`,
                    value: true,
                },
            ],
        });
        sendTicketMessage(
            (res) => {
                console.log(res.data.response);
                // dispatch({
                //   payload: [
                //     {
                //       type: UPDATE_PROP,
                //       prop: `followup`,
                //       value: [...state.followup,res.data.response.data],
                //     },
                //   ],
                // });
                addToast({
                    type: "success",
                    description: "Message sent successfully",
                    title: "Success",
                });
            },
            (error) => {
                console.log(error);
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: `error`,
                            value: "cannot load this page",
                        },
                    ],
                });
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: `messagesLoading`,
                            value: false,
                        },
                    ],
                });
            },
            formData
        );
    };

    useEffect(() => {
        // dispatch({
        //     payload: [
        //         {
        //             type: UPDATE_PROP,
        //             prop: "ticketDetails",
        //             value: {
        //                 id: 125005,
        //                 companyName: "Ps solustions",
        //                 ticketDate: "2022/07/24",
        //                 assignee: "Assignee 1",
        //                 activeStatus: 2,
        //                 issue: "issue issue issue issue issue issue issue issue issue issue issue issue issue issue issue issue issue issue issue issue issue issue issue issue",
        //             },
        //         },
        //     ],
        // });

        dispatch({
            payload: [
                {
                    type: UPDATE_PROP,
                    prop: `ticketsLoading`,
                    value: true,
                },
            ],
        });
        getTicketDetails(
            (res) => {
                console.log("tickeydetails", res.data.response);
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: `ticketDetails`,
                            value: {
                                id: res.data.response.ticket_number,
                                companyName: res.data.response.company_name,
                                ticketDate: res.data.response.created_at,
                                assignee: res.data.response.name,
                                activeStatus: res.data.response.status,
                                issue: res.data.response.description,
                            },
                        },
                    ],
                });
            },
            (error) => {
                console.log(error);
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: `error`,
                            value: "cannot load this page",
                        },
                    ],
                });
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: `ticketsLoading`,
                            value: false,
                        },
                    ],
                });
            },
            { id: ticketId }
        );

        getTicketThread(
            (res) => {
                console.log("thread", res.data.response.data);
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: `followup`,
                            value: res.data.response.data,
                        },
                    ],
                });
            },
            (error) => {
                console.log(error);
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: `error`,
                            value: "cannot load this page",
                        },
                    ],
                });
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: `ticketsLoading`,
                            value: false,
                        },
                    ],
                });
            },
            { ticket_id: ticketId }
        );
    }, [state.messagesLoading]);

    return { actions: {}, handleSendMessage };
};

export default Logic;
