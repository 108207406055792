import { faCalendarAlt, faClock } from "@fortawesome/free-regular-svg-icons";
import AppInput from "../../../components/general/app-input/AppInput";
import AppSelect from "../../../components/general/app-select/AppSelect";
import Card from "../../../components/general/card/Card";
import Label from "../../../components/general/label/Label";
import RadioInput from "../../../components/general/radio-input/RadioInput";
import Title from "../../../components/general/title/Title";
import useLogic from "../../../helpers/hooks/use-logic";
import styles from "./AddSchedule.module.css";
import { filterSelectStyles } from "./Constants";
import Logic, { INITIAL_STATE } from "./logic";
// import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppButton from "../../../components/general/app-button/AppButton";
import LoadingSpinner from "../../../components/general/loading-spinner/LoadingSpinner";
import ValidationProvider from "../../../components/general/validation-provider/ValidationProvider";
import useValidator from "../../../helpers/hooks/use-validator";
// import { actions } from "react-table";
import DatePicker from "react-multi-date-picker";
// import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import MultiSelect from "react-select";

const datePickerStyle = {
  height: "100%",
  width: "100%",
  padding: "0px 10px",
  boxShadow: "none",
  border: 0,
  display: "flex",
};

const datePickerContainerStyle = {
  width: "100%",
};

const AddSchedule = () => {
  const { t } = useTranslation();
  const {
    state,
    updateState,
    addSchedule,
    updateSchedule,
    navigate,
    id,
    minDate,
  } = useLogic({
    INITIAL_STATE,
    Logic,
  });
  // console.log(state.form);

  const { errors, onBlur, onChange, handleSubmit } = useValidator({
    defaultValues: {
      ...state.form,
    },
    setValue: (nextFormState) => {
      updateState({
        prop: "form",
        value: nextFormState,
      });
    },
    onSubmit: addSchedule,
    validations: {
      selectedDepartment: {
        required: false,
        validation_name: "Department",
      },
      selectedChecklist: {
        required: true,
        validation_name: "Check list",
      },
      selectedAssignee: {
        required: true,
        validation_name: "Assignee",
      },
      selectedLocation: {
        required: true,
        validation_name: "Location",
      },
      selectedInput: {
        required: true,
        validation_name: "Frequency",
      },
      // selectedCustomFreqInput: {
      //   required: state.form.selectedInput === "custom",
      //   validation_name: "Custom Frequency",
      // },
      start_date: {
        required:
          !!state.form.selectedInput && state.form.selectedInput !== "custom",
        validation_name: "Start date",
      },
      end_date: {
        required:
          !!state.form.selectedInput && state.form.selectedInput !== "custom",
        validation_name: "End date",
      },
      selectedTimeframeInput: {
        required: !!state.form.selectedInput,
        validation_name: "Time Frame",
      },
      selectedTimeframeNatureInput: {
        // required: !!state.form.selectedInput,
        required: state.form.selectedTimeframeInput == 1,
        validation_name: "Time Frame Nature",
      },
      start_time: {
        required: state.form.selectedTimeframeInput == 1,
        validation_name: "Start Time",
      },
      due_time: {
        required: state.form.selectedTimeframeInput == 1,
        validation_name: "Due Time",
      },
      selectedWeekDay: {
        required:
          (state.form.selectedCustomFreqInput == "weekly" &&
            state.form.selectedInput == "custom") ||
          state.form.selectedInput == "weekly",
        validation_name: "Week Day",
      },
      due_on: {
        required:
          (state.form.selectedCustomFreqInput == "monthly" &&
            state.form.selectedInput == "custom") ||
          state.form.selectedInput == "monthly",
        validation_name: "Due On",
        isNumeric:
          (state.form.selectedCustomFreqInput == "monthly" &&
            state.form.selectedInput == "custom") ||
          state.form.selectedInput == "monthly",
        min: 1,
        max: 31,
      },
      custom_selected_dates: {
        required:
          state.form.selectedInput == "custom" &&
          state.form.selectedCustomFreqInput == "custom",
        validation_name: "Selected Dates",
      },
    },
  });
  return (
    <>
      <div className={styles.title}>
        <Title title={`${id ? t("updateSchedule") : t("newSchedule")}`}></Title>
      </div>
      <Card style={{ padding: "20px 10px" }}>
        <LoadingSpinner isLoading={state.pageLoading} error={state.error}>
          <div className={styles.container}>
            <div className={styles.sectionContainer}>
              <Title
                title={t("details")}
                border={true}
                style={{ color: "var(--primary)", fontSize: "16px" }}
              />
              <div className={styles.content}>
                <div className={styles.groupControl}>
                  <ValidationProvider
                    error={errors.selectedDepartment}
                    render={({ requiredTitle }) => (
                      <Label
                        name={t("department")}
                        className={styles.label}
                        classText={styles.labelText}
                      >
                        <div className={styles.inputContainer}>
                          <AppSelect
                            options={state.departments.map((element) => ({
                              value: element.id,
                              label: element.name,
                            }))}
                            styles={filterSelectStyles}
                            value={state.form.selectedDepartment}
                            onChange={(e) => onChange("selectedDepartment", e)}
                            onBlur={(e) => onBlur("selectedDepartment")}
                          />
                        </div>
                      </Label>
                    )}
                  />
                </div>
                <div className={styles.groupControl}>
                  <ValidationProvider
                    error={errors.selectedChecklist}
                    render={({ requiredTitle }) => (
                      <Label
                        name={requiredTitle(t("audit"))}
                        className={styles.label}
                        classText={styles.labelText}
                      >
                        <div className={styles.inputContainer}>
                          <AppSelect
                            options={state.checklist.map((element) => ({
                              value: element.id,
                              label: element.name,
                            }))}
                            styles={filterSelectStyles}
                            value={state.form.selectedChecklist}
                            onChange={(e) => onChange("selectedChecklist", e)}
                            onBlur={(e) => onBlur("selectedChecklist")}
                          />
                        </div>
                      </Label>
                    )}
                  />
                </div>
                <div className={styles.groupControl}>
                  <ValidationProvider
                    error={errors.selectedAssignee}
                    render={({ requiredTitle }) => (
                      <Label
                        name={requiredTitle(t("assignee"))}
                        className={styles.label}
                        classText={styles.labelText}
                      >
                        <div className={styles.inputContainer}>
                          <AppSelect
                            options={state.asignees.map((element) => ({
                              value: element.id,
                              label: element.name,
                            }))}
                            styles={filterSelectStyles}
                            value={state.form.selectedAssignee}
                            onChange={(e) => onChange("selectedAssignee", e)}
                            onBlur={(e) => onBlur("selectedAssignee")}
                          />
                        </div>
                      </Label>
                    )}
                  />
                </div>
                <div className={styles.groupControl}>
                  <ValidationProvider
                    error={errors.selectedLocation}
                    render={({ requiredTitle }) => (
                      <Label
                        name={requiredTitle(t("location"))}
                        className={styles.label}
                        classText={styles.labelText}
                      >
                        <div className={styles.inputContainer}>
                          <MultiSelect
                            options={state.locations.map((element) => ({
                              value: element.id,
                              label: element.name,
                            }))}
                            styles={filterSelectStyles}
                            value={state.form.selectedLocation}
                            onChange={(e) => {
                              console.log("-----------------");
                              console.log(e);
                              // console.log(state.form.selectedLocation);
                              onChange("selectedLocation", e);
                            }}
                            onBlur={(e) => onBlur("selectedLocation")}
                            isMulti
                          />
                          {/* <AppSelect
                            options={state.locations.map((element) => ({
                              value: element.id,
                              label: element.name,
                            }))}
                            styles={filterSelectStyles}
                            value={state.form.selectedLocation}
                            onChange={(e) => onChange("selectedLocation", e)}
                            onBlur={(e) => onBlur("selectedLocation")}
                          /> */}
                        </div>
                      </Label>
                    )}
                  />
                </div>
              </div>
              <div className={styles.actionButton}>
                <AppButton
                  className="primaryButton"
                  // onClick={id ? updateSchedule : addSchedule}
                  onClick={handleSubmit}
                  value={t("save")}
                  isLoading={state.saveLoading}
                />

                <AppButton
                  className="whiteButton"
                  onClick={() => navigate("/portal/schedule")}
                  value={t("cancel")}
                  style={{ margin: "0px 15px" }}
                />
              </div>
            </div>

            <div className={styles.sectionContainer}>
              {!id && (
                <>
                  <Title
                    title={t("frequency")}
                    border={true}
                    style={{ color: "var(--primary)", fontSize: "16px" }}
                  />
                  <div className={styles.content}>
                    <div
                      className={styles.groupControl}
                      style={{ width: "75%" }}
                    >
                      <ValidationProvider
                        error={errors.selectedInput}
                        render={({ requiredTitle }) => (
                          <Label
                            name={requiredTitle(t("frequency"))}
                            className={styles.label}
                            classText={styles.labelText}
                          >
                            <div className={styles.radioButtonGroup}>
                              <RadioInput
                                name="daily"
                                value="daily"
                                label={t("daily")}
                                isChecked={state.form.selectedInput == "daily"}
                                handleChange={(inputValue) =>
                                  onChange("selectedInput", inputValue)
                                }
                              />
                              <RadioInput
                                name="weekly"
                                value="weekly"
                                label={t("weekly")}
                                isChecked={state.form.selectedInput == "weekly"}
                                handleChange={(inputValue) =>
                                  onChange("selectedInput", inputValue)
                                }
                              />
                              <RadioInput
                                name="monthly"
                                value="monthly"
                                label={t("monthly")}
                                isChecked={
                                  state.form.selectedInput == "monthly"
                                }
                                handleChange={(inputValue) =>
                                  onChange("selectedInput", inputValue)
                                }
                              />
                              <RadioInput
                                name="custom"
                                value="custom"
                                label={t("custom")}
                                isChecked={state.form.selectedInput == "custom"}
                                handleChange={(inputValue) =>
                                  onChange("selectedInput", inputValue)
                                }
                              />
                            </div>
                          </Label>
                        )}
                      />
                    </div>
                    {state.form.selectedInput && (
                      <>
                        {state.form.selectedInput == "custom" && (
                          <div
                            className={styles.timeContainer}
                            style={{ width: "80%" }}
                          >
                            <div
                              className={`${styles.groupControl} ${styles.timeSubContainer}`}
                            ></div>
                          </div>
                        )}
                        {((state.form.selectedCustomFreqInput == "weekly" &&
                          state.form.selectedInput == "custom") ||
                          state.form.selectedInput == "weekly") && (
                          <div className={styles.groupControl}>
                            <ValidationProvider
                              error={errors.selectedWeekDay}
                              render={({ requiredTitle }) => (
                                <Label
                                  name={requiredTitle(t("weekDay"))}
                                  className={styles.label}
                                  classText={styles.labelText}
                                >
                                  <div className={styles.inputContainer}>
                                    <AppSelect
                                      options={state.weekDay.map((element) => ({
                                        value: element,
                                        label: element,
                                      }))}
                                      styles={filterSelectStyles}
                                      value={state.form.selectedWeekDay}
                                      onChange={(e) =>
                                        onChange("selectedWeekDay", e)
                                      }
                                      onBlur={(e) => onBlur("selectedWeekDay")}
                                    />
                                  </div>
                                </Label>
                              )}
                            />
                          </div>
                        )}
                        {/* {state.form.selectedCustomFreqInput == "monthly" && (
                          <div className={styles.groupControl}>
                            <Label
                              name="Day of Month"
                              className={styles.label}
                              classText={styles.labelText}
                            >
                              <div className={styles.inputContainer}>
                                <AppSelect
                                  options={state.weekDay.map((element) => ({
                                    value: element.id,
                                    label: element.name,
                                  }))}
                                  styles={filterSelectStyles}
                                  value={state.form.selectedWeekDay}
                                  onChange={(e) => {
                                    updateState({
                                      prop: "selectedWeekDay",
                                      value: e,
                                    });
                                  }}
                                />
                              </div>
                            </Label>
                          </div>
                        )} */}

                        {state.form.selectedInput == "custom" && (
                          <div className={styles.groupControl}>
                            <ValidationProvider
                              error={errors.start_date}
                              render={({ requiredTitle }) => (
                                <Label
                                  name={requiredTitle(t("selectedDates"))}
                                  className={styles.label}
                                  classText={styles.labelText}
                                >
                                  <div
                                    className={styles.inputContainer}
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <DatePicker
                                      id="multipleDatePicker"
                                      minDate={minDate()}
                                      style={datePickerStyle}
                                      containerStyle={datePickerContainerStyle}
                                      placeholder="dd/mm/yyyy"
                                      format="DD/MM/YYYY"
                                      multiple
                                      selected={
                                        state.form.custom_selected_dates
                                      }
                                      onChange={(date) => {
                                        onChange("custom_selected_dates", date);
                                      }}
                                      onBlur={(e) =>
                                        onBlur("custom_selected_dates")
                                      }
                                      plugins={[<DatePanel />]}
                                    />

                                    <FontAwesomeIcon
                                      icon={faCalendarAlt}
                                      className={styles.datepickerIcon}
                                    />
                                  </div>
                                </Label>
                              )}
                            />
                          </div>
                        )}
                        {state.form.selectedInput !== "custom" && (
                          <div className={styles.groupControl}>
                            <ValidationProvider
                              error={errors.start_date}
                              render={({ requiredTitle }) => (
                                <Label
                                  name={requiredTitle(t("start"))}
                                  className={styles.label}
                                  classText={styles.labelText}
                                >
                                  <div
                                    className={styles.inputContainer}
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <DatePicker
                                      id="start_date"
                                      minDate={minDate()}
                                      style={datePickerStyle}
                                      containerStyle={datePickerContainerStyle}
                                      placeholder="dd/mm/yyyy"
                                      format="DD/MM/YYYY"
                                      selected={state.form.start_date}
                                      onChange={(date) => {
                                        onChange(
                                          "start_date",
                                          new Date(date)
                                            .toISOString()
                                            .split("T")[0]
                                        );
                                      }}
                                      onBlur={(e) => onBlur("start_date")}
                                    />
                                    <FontAwesomeIcon
                                      icon={faCalendarAlt}
                                      className={styles.datepickerIcon}
                                    />
                                  </div>
                                </Label>
                              )}
                            />
                          </div>
                        )}
                        {((state.form.selectedCustomFreqInput == "monthly" &&
                          state.form.selectedInput == "custom") ||
                          state.form.selectedInput == "monthly") && (
                          <div className={styles.groupControl}>
                            <ValidationProvider
                              error={errors.due_on}
                              render={({ requiredTitle }) => (
                                <Label
                                  name={requiredTitle(t("dueOn"))}
                                  className={styles.label}
                                  classText={styles.labelText}
                                >
                                  <div className={styles.inputContainer}>
                                    <AppInput
                                      type="number"
                                      containerStyle={{
                                        height: "100%",
                                        padding: "0px 10px",
                                        position: "relative",
                                      }}
                                      value={state.form.due_on}
                                      onChange={(e) =>
                                        onChange("due_on", e.target.value)
                                      }
                                      onBlur={(e) => onBlur("due_on")}
                                      min={1}
                                      max={31}
                                    />
                                  </div>
                                </Label>
                              )}
                            />
                          </div>
                        )}

                        {state.form.selectedInput !== "custom" && (
                          <div className={styles.groupControl}>
                            <ValidationProvider
                              error={errors.end_date}
                              render={({ requiredTitle }) => (
                                <Label
                                  name={requiredTitle(t("end"))}
                                  className={styles.label}
                                  classText={styles.labelText}
                                >
                                  <div
                                    className={styles.inputContainer}
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <DatePicker
                                      id="end_date"
                                      minDate={state.form.start_date}
                                      style={datePickerStyle}
                                      containerStyle={datePickerContainerStyle}
                                      placeholder="dd/mm/yyyy"
                                      format="DD/MM/YYYY"
                                      selected={state.form.end_date}
                                      onChange={(date) => {
                                        console.log(date);
                                        onChange(
                                          "end_date",
                                          new Date(date)
                                            .toISOString()
                                            .split("T")[0]
                                        );
                                      }}
                                      onBlur={(e) => onBlur("end_date")}
                                    />
                                    <FontAwesomeIcon
                                      icon={faCalendarAlt}
                                      className={styles.datepickerIcon}
                                    />
                                  </div>
                                </Label>
                              )}
                            />
                          </div>
                        )}
                        <div
                          className={styles.timeContainer}
                          style={{ width: "80%" }}
                        >
                          <div
                            className={`${styles.groupControl} ${styles.timeSubContainer}`}
                          >
                            <ValidationProvider
                              error={errors.selectedTimeframeInput}
                              render={({ requiredTitle }) => (
                                <Label
                                  name={requiredTitle(t("timeFrame"))}
                                  className={styles.label}
                                  classText={styles.labelText}
                                >
                                  <div className={styles.radioButtonGroup}>
                                    <RadioInput
                                      name="Anytime"
                                      value={0}
                                      label={t("anytime")}
                                      isChecked={
                                        state.form.selectedTimeframeInput == 0
                                      }
                                      handleChange={(value) => {
                                        onChange(
                                          "selectedTimeframeInput",
                                          value
                                        );
                                      }}
                                    />
                                    <RadioInput
                                      name={"Range"}
                                      value={1}
                                      label={t("range")}
                                      isChecked={
                                        state.form.selectedTimeframeInput == 1
                                      }
                                      handleChange={(value) => {
                                        onChange(
                                          "selectedTimeframeInput",
                                          value
                                        );
                                      }}
                                    />
                                  </div>
                                </Label>
                              )}
                            />
                          </div>
                          {/* {state.selectedCustomFreqInput != "monthly" && ( */}
                          <div
                            className={`${styles.groupControl} ${styles.timeSubContainer}`}
                          >
                            {state.form.selectedTimeframeInput == 1 && (
                              <ValidationProvider
                                error={errors.selectedTimeframeNatureInput}
                                render={({ requiredTitle }) => (
                                  <Label
                                    name={requiredTitle(t("timeFrameNature"))}
                                    className={styles.label}
                                    classText={styles.labelText}
                                  >
                                    <div className={styles.radioButtonGroup}>
                                      <RadioInput
                                        name="Suggested"
                                        value={0}
                                        label={t("suggested")}
                                        isChecked={
                                          state.form
                                            .selectedTimeframeNatureInput == 0
                                        }
                                        handleChange={(value) => {
                                          onChange(
                                            "selectedTimeframeNatureInput",
                                            value
                                          );
                                        }}
                                      />
                                      <RadioInput
                                        name="Strict"
                                        value={1}
                                        label={t("strict")}
                                        isChecked={
                                          state.form
                                            .selectedTimeframeNatureInput == 1
                                        }
                                        handleChange={(value) =>
                                          // updateState({
                                          //   prop: "selectedTimeframeNatureInput",
                                          //   value: value,
                                          // })
                                          onChange(
                                            "selectedTimeframeNatureInput",
                                            value
                                          )
                                        }
                                      />
                                    </div>
                                  </Label>
                                )}
                              />
                            )}
                          </div>
                          {/* )} */}
                        </div>
                        {state.form.selectedTimeframeInput == 1 && (
                          <div className={styles.timeContainer}>
                            <div
                              className={`${styles.groupControl} ${styles.timeSubContainer}`}
                            >
                              <ValidationProvider
                                error={errors.start_time}
                                render={({ requiredTitle }) => (
                                  <Label
                                    name={requiredTitle(t("startTime"))}
                                    className={styles.label}
                                    classText={styles.labelText}
                                  >
                                    <div className={styles.inputContainer}>
                                      <AppInput
                                        type="time"
                                        containerStyle={{
                                          height: "100%",
                                          padding: "0px 10px",
                                          position: "relative",
                                        }}
                                        className={styles.inputDate}
                                        value={state.form.start_time}
                                        onChange={(e) => {
                                          // updateState({
                                          //   prop: "start_time",
                                          //   value: e.target.value,
                                          // });
                                          onChange(
                                            "start_time",
                                            e.target.value
                                          );
                                        }}
                                        onBlur={(e) => {
                                          onBlur("start_time");
                                        }}
                                      >
                                        <span className={styles.openbutton}>
                                          <FontAwesomeIcon
                                            icon={faClock}
                                            style={{
                                              fontSize: 20,
                                              color: "var(--primary)",
                                            }}
                                          />
                                        </span>
                                      </AppInput>
                                    </div>
                                  </Label>
                                )}
                              />
                            </div>
                            <div
                              className={`${styles.groupControl} ${styles.timeSubContainer}`}
                            >
                              <ValidationProvider
                                error={errors.due_time}
                                render={({ requiredTitle }) => (
                                  <Label
                                    name={requiredTitle(t("dueTime"))}
                                    className={styles.label}
                                    classText={styles.labelText}
                                  >
                                    <div className={styles.inputContainer}>
                                      <AppInput
                                        type="time"
                                        containerStyle={{
                                          height: "100%",
                                          padding: "0px 10px",
                                          position: "relative",
                                        }}
                                        className={styles.inputDate}
                                        value={state.form.due_time}
                                        onChange={(e) => {
                                          // updateState({
                                          //   prop: "start_time",
                                          //   value: e.target.value,
                                          // });
                                          onChange("due_time", e.target.value);
                                        }}
                                        onBlur={(e) => {
                                          onBlur("due_time");
                                        }}
                                      >
                                        <span className={styles.openbutton}>
                                          <FontAwesomeIcon
                                            icon={faClock}
                                            style={{
                                              fontSize: 20,
                                              color: "var(--primary)",
                                            }}
                                          />
                                        </span>
                                      </AppInput>
                                    </div>
                                  </Label>
                                )}
                              />
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </LoadingSpinner>
      </Card>
    </>
  );
};

export default AddSchedule;
