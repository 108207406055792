import {
    faCalendarAlt,
    faChartPie,
    faClipboard,
    faClipboardCheck,
    faClipboardList,
    faCogs,
    faDollarSign,
    faHeadset,
    faIdCard,
    faUsers,
    faUserShield,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * PERMISSIONS
 * 
       1 => "Portal" 
       2 => "App" 
       3 => "Analytics" 
       4 => "Admin" 
 */

const NavLinks = [
    {
        name: "analytics",
        to: "/analytics",
        icon: <FontAwesomeIcon icon={faChartPie} />,
        items: [
            {
                name: "overview",
                to: "overview",
            },
            {
                name: "performance",
                to: "performance",
            },
        ],

        allowedFeatures: [1, 4],
        feature: "ANALYTICS",
    },
    {
        name: "audits",
        to: "/portal/audits",
        icon: <FontAwesomeIcon icon={faClipboardList} />,
        allowedFeatures: [1, 4], // all
        feature: "AUDITS",
    },
    {
        name: "schedule",
        to: "schedule",
        icon: <FontAwesomeIcon icon={faCalendarAlt} />,
        allowedFeatures: [1, 4], // all
        feature: "SCHEDULE",
    },
    {
        name: "auditTemplate",
        to: "forms-checklists",
        icon: <FontAwesomeIcon icon={faClipboard} />,
        allowedFeatures: [1, 4], // all
        feature: "AUDIT_TEMPLATES",
    },
    {
        name: "actionPlans",
        to: "action-plan-new",
        icon: <FontAwesomeIcon icon={faClipboardCheck} />,
        allowedFeatures: [4],
        feature: "ACTION_PLANS",
    },
    {
        name: "users",
        to: "users",
        icon: <FontAwesomeIcon icon={faUsers} />,
        allowedFeatures: [4], // admin and portal
        feature: "USERS",
    },
    {
        name: "advanced",
        to: "/advanced",
        icon: <FontAwesomeIcon icon={faUserShield} />,
        items: [
            {
                name: "permissions",
                to: "permissions",
            },
            {
                name: "roles",
                to: "roles",
            },
            // {
            //     name: "actions",
            //     to: "/analytics/action-plan",
            // },
            // {
            //   name: "Email Templates",
            //   to: "email-templates",
            // },
        ],

        allowedFeatures: [4],
        feature: "ROLES_PERMISSIONS",
        // suffix: (
        //   <p style={{ backgroundColor: "green", borderRadius: 30, padding: 5 }}>
        //     10
        //   </p>
        // ),
    },
    {
        name: "masterData",
        to: "/master-data",
        icon: <FontAwesomeIcon icon={faCogs} />,
        items: [
            {
                name: "departments",
                to: "departments",
            },
            {
                name: "areas",
                to: "Areas",
            },
            {
                name: "locations",
                to: "locations",
            },
        ],

        allowedFeatures: [1, 4],
        feature: "MASTER_DATA",
    },

    {
        name: "companyInfo",
        to: "settings",
        icon: <FontAwesomeIcon icon={faIdCard} />,
        allowedFeatures: [4],
        feature: "COMPANY_INFO",
        // suffix: (
        //   <p style={{ backgroundColor: "green", borderRadius: 30, padding: 5 }}>
        //     10
        //   </p>
        // ),
    },
    {
        name: "Tickets",
        to: "tickets",
        icon: <FontAwesomeIcon icon={faHeadset} />,
        allowedFeatures: [4],
        feature: "TICKETS",
    },
    {
        name: "subscriptions",
        to: "/admin/subscriptions",
        icon: <FontAwesomeIcon icon={faDollarSign} />,
        allowedFeatures: [4],
        feature: "SUBSCRIPTIONS",
    },
];

export default NavLinks;
