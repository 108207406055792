import {
    getHeatMap,
    getLineChart,
    getStackedBarChart,
} from "../../../api/services/PerformanceServices";
import { UPDATE_PROP } from "../../../store/ActionTypes";
import UseAppToast from "../../../helpers/hooks/use-app-toast";
import { ERROR } from "../../../components/general/app-toast/AppToast";
import { fetchAllAuditTemplates } from "../../../api/services/AuditServices";
import { getAllDepartments } from "../../../api/services/DepartmentServices";
import { fetchAllLocations } from "../../../api/services/LocationServices";
import { fetchAllAreas } from "../../../api/services/AreaServices";
import { fetchAllUsers } from "../../../api/services/UserServices";
import { useEffect } from "react";
import { extractErrorMessage } from "../../../helpers/Common";

const Logic = (dispatch, state) => {
    const { addToast } = UseAppToast();
    const filterData = () => {
        //in case of multiple values for a filter we need to use map

        const auditIds = state.selectedAudit == null ? [] : state.selectedAudit.map((e) => e.value);
        const locIds =
            state.selectedLocation == null ? [] : state.selectedLocation.map((e) => e.value);
        const auditroIds =
            state.selectedAuditor == null ? [] : state.selectedAuditor.map((e) => e.value);
        const areaIds = state.selectedArea == null ? [] : state.selectedArea.map((e) => e.value);
        const depIds =
            state.selectedDepartment == null ? [] : state.selectedDepartment.map((e) => e.value);

        dispatch({
            payload: [
                {
                    type: UPDATE_PROP,
                    prop: `filters.audit_ids`,
                    value: auditIds,
                },
                {
                    type: UPDATE_PROP,
                    prop: `filters.department_ids`,
                    value: depIds,
                },
                {
                    type: UPDATE_PROP,
                    prop: `filters.location_ids`,
                    value: locIds,
                },
                {
                    type: UPDATE_PROP,
                    prop: `filters.area_ids`,
                    value: areaIds,
                },
                {
                    type: UPDATE_PROP,
                    prop: `filters.auditor_ids`,
                    value: auditroIds,
                },
                {
                    type: UPDATE_PROP,
                    prop: `filters.from`,
                    value: state.dateFrom,
                },
                {
                    type: UPDATE_PROP,
                    prop: `filters.to`,
                    value: state.dateTo,
                },
            ],
        });
    };

    const dataBarChart = () => {
        getStackedBarChart(
            { filters: state.filters },
            (res) => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: `getStackedBarChart`,
                            value: res.response,
                        },
                        {
                            type: UPDATE_PROP,
                            prop: `stackedBarChart`,
                            value: {
                                series: res.response.series,
                                options: {
                                    ...state.stackedBarChart.options,
                                    xaxis: {
                                        categories: res.response.xAxis.map((item) => item.name),
                                    },
                                },
                            },
                        },
                    ],
                });
                // console.log("API Response stackedBarChart:", res.response);
            },
            (e) => {
                addToast({ type: ERROR, description: e.message, title: "Error" });
            },
            () => {
                dispatch({
                    payload: [{ type: UPDATE_PROP, prop: `stackedBarChartLoading`, value: false }],
                });
            }
        );
    };

    const dataLineChart = () => {
        getLineChart(
            { filters: state.filters },
            (res) => {
                dispatch({
                    payload: [
                        { type: UPDATE_PROP, prop: `getLineChart`, value: res.response },
                        {
                            type: UPDATE_PROP,
                            prop: `lineChart`,
                            value: {
                                series: res.response.series,
                                options: {
                                    ...state.lineChart.options,
                                    xaxis: {
                                        categories: res.response.xAxis.map((item) => item.name),
                                    },
                                },
                            },
                        },
                    ],
                });
                // console.log("API Response lineChart:", res.response);
            },
            (e) => {
                addToast({ type: ERROR, description: e.message, title: "Error" });
            },
            () => {
                dispatch({
                    payload: [{ type: UPDATE_PROP, prop: `lineChartLoading`, value: false }],
                });
            }
        );
    };

    const dataHeatChart = () => {
        getHeatMap(
            { filters: state.filters },
            (res) => {
                const seriesData = res.response.map((location) => {
                    return {
                        name: location.location_name,
                        data: location.audit_percentages.map((audit) => ({
                            x: audit.name,
                            y: audit.percentage,
                        })),
                    };
                });

                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: `getHeatMap`,
                            value: res.response,
                        },
                        {
                            type: UPDATE_PROP,
                            prop: `heatMap`,
                            value: {
                                series: seriesData,
                                options: {
                                    ...state.heatMap.options,
                                    xaxis: {
                                        categories: seriesData[0]?.data.map((d) => d.x),
                                    },
                                },
                            },
                        },
                    ],
                });
                console.log("API Response HeatMap:", res.response);
            },
            (e) => {
                addToast({ type: ERROR, description: e.message, title: "Error" });
            },
            () => {
                dispatch({
                    payload: [{ type: UPDATE_PROP, prop: `heatMapLoading`, value: false }],
                });
            }
        );
    };

    useEffect(() => {
        getAllDepartments(
            (res) => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: `departments`,
                            value: res.data.response,
                        },
                    ],
                });
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {},
            {
                pageSize: 10000000,
            }
        );
        fetchAllAreas(
            (res) => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: `area`,
                            value: res.data.response,
                        },
                    ],
                });
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {},
            {
                pageSize: 10000000,
            }
        );
        fetchAllLocations(
            (res) => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: `locations`,
                            value: res.data.response,
                        },
                    ],
                });
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {},
            {
                pageSize: 10000000,
            }
        );
        fetchAllUsers(
            (res) => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: `auditors`,
                            value: res.data.response,
                        },
                    ],
                });
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {},
            {
                pageSize: 10000000,
            }
        );
        fetchAllAuditTemplates(
            (res) => {
                dispatch({
                    payload: [
                        {
                            type: UPDATE_PROP,
                            prop: `audits`,
                            value: res.data.response,
                        },
                    ],
                });
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {},
            {
                pageSize: 10000000,
            }
        );
        filterData();
    }, []);

    return { actions: { filterData, dataBarChart, dataLineChart, dataHeatChart } };
};

export default Logic;
