import AppButton from "../../../components/general/app-button/AppButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

export const ScheduleColumn = (
    actions,
    translate = (term) => {
        return term;
    }
) => {
    return [
        {
            Header: `${translate("audit")}`,
            accessor: "template_name",
        },
        {
            Header: `${translate("location")}`,
            accessor: "location_name",
        },
        {
            Header: `${translate("scheduleDate")}`,
            accessor: "date",
        },
        {
            Header: `${translate("scheduleTime")}`,
            accessor: (row) => {
                if (row.start_time && row.due_time) {
                    return `${moment(row.start_time, "hh:mm").format("hh:mm A")} - ${moment(
                        row.due_time,
                        "hh:mm"
                    ).format("hh:mm A")}`;
                }
            },
        },
        {
            Header: `${translate("assignee")}`,
            accessor: "username",
        },
        {
            Header: `${translate("actions")}`,
            // className: "frozen",
            style: {
                textAlign: "center",
            },
            Cell: ({ row: { values, original } }) => (
                <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                    {/* <AppButton
                        classes="buttonIcon"
                        onClick={() => actions.navigate(`/add-schedule/${original.id}`)}
                        value="Edit"
                        style={{
                            color: "#2e5bff",
                        }}
                        icon={<FontAwesomeIcon icon={faEdit} style={{ marginRight: "5px" }} />}
                    /> */}
                    <AppButton
                        classes="buttonIcon"
                        style={{ color: "#fb3737" }}
                        onClick={() => actions.rowDelete([original])}
                        value ={`${translate("delete")}`}
                        icon={<FontAwesomeIcon icon={faTrashAlt} style={{margin: "0px 5px" }} />}
                    />
                </div>
            ),
        },
    ];
};
