import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faEdit, faRedo } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import AppButton from "../../components/general/app-button/AppButton";

export const subscriptionColumns = (
  actions,
  translate = (term) => {
    return term;
  }
) => {
  return [
    {
      Header: `${translate("plan")}`,
      accessor: "plan_name",
    },
    {
      Header: `${translate("status")}`,
      accessor: "status",
    },
    {
      Header: `${translate("users")}`,
      accessor: "number_of_users",
      Cell: (props) => {
        return props.row.original.number_of_users === null
          ? "∞"
          : props.row.original.number_of_users;
      },
    },
    {
      Header: `${translate("expiryDate")}`,
      accessor: "subscription_end_date",
    },
    {
      Header: `${translate("actions")}`,
      accessor: "actions",
      Cell: (props) => {
        var diff,
          aDay = 86400000;

        diff = Math.floor(
          (Date.parse(props.row.original.subscription_end_date) -
            Date.parse(props.row.original.subscription_start_date)) /
            aDay
        );

        return (
          props.row.original.status === "active" &&
          diff <= 0 && (
            <div className="flex justify-center">
              {console.log(props.row.original)}

              <AppButton
                classes="buttonIcon"
                onClick={() => actions.navigate(`/admin/plans`)}
                value="Renew"
                style={{
                  color:
                    props.row.original.status === "active" && diff <= 0
                      ? "#2e5bff"
                      : "#c7c3c3",
                }}
                icon={
                  <FontAwesomeIcon
                    icon={faRedo}
                    style={{ marginRight: "5px" }}
                  />
                }
                disabled={
                  !(props.row.original.status === "active" && diff <= 0)
                }
              />
            </div>
          )
        );
      },
    },
  ];
};
