import Classes from "./ForgetPassword.module.css";
import AppInput from "../../components/general/app-input/AppInput";
import AppButton from "../../components/general/app-button/AppButton";
import Logo from "../../components/logo/Logo";
import useLogic from "../../helpers/hooks/use-logic";
import Logic, { INITIAL_STATE } from "./logic";

const ForgetPassword = () => {
  const { state, updateState, handleSubmit } = useLogic({
    INITIAL_STATE,
    Logic,
  });
  return (
    <div className={Classes.container}>
      <div className={Classes.subContainer}>
        <div className={Classes.header}>
          <Logo classes={Classes.logoSize} />
          {/* <div className={Classes.logo}>GAWDA</div> */}
          <div className={Classes.slogan}>Unleash Your Potential</div>
        </div>
        <div className={Classes.loginForm}>
          <AppInput
            type="text"
            placeholder="Username"
            onChange={(e) => {
              updateState({ prop: "username", value: e.target.value });
            }}
            value={state.username}
            containerStyle={{
              marginBottom: 35,
              paddingLeft: 15,
              paddingRight: 20,
              height: 50,
              width: "60%",
            }}
            inputStyle={{ margin: "0px 7px" }}
          />

          <AppButton
            value="Submit"
            className="rounded"
            onClick={handleSubmit}
            style={{
              backgroundColor: "var(--darkPurble)",
              color: "white",
              width: "30%",
              height: 50,
              fontSize: 22,
              marginBottom: 20,
            }}
            isLoading={state.loading}
          />
        </div>
      </div>
    </div>
  );
};
export default ForgetPassword;
